import * as countryCodes from '../country/countryCodes';

import type { MarketingConsentConfig, MarketingConsentCountry } from './types';

const MIDDLE_EAST_COUNTRIES: MarketingConsentCountry[] = [
  // Middle East
  countryCodes.UNITED_ARAB_EMIRATES,
  countryCodes.BAHRAIN,
  countryCodes.EGYPT,
  countryCodes.IRAQ,
  countryCodes.IRAN,
  countryCodes.JORDAN,
  countryCodes.KUWAIT,
  countryCodes.LEBANON,
  countryCodes.OMAN,
  countryCodes.QATAR,
  countryCodes.SAUDI_ARABIA,
  countryCodes.SUDAN,
  countryCodes.SOUTH_SUDAN,
  countryCodes.SYRIAN_ARAB_REPUBLIC,
  countryCodes.YEMEN,
];

// list of countries for what we know marketing consent rules
export const MARKETING_CONSENT_COUNTIES = [
  countryCodes.UNITED_STATES,
  countryCodes.CANADA,
  countryCodes.BELGIUM,
  countryCodes.NETHERLANDS,
  countryCodes.LUXEMBOURG,
  countryCodes.DENMARK,
  countryCodes.FINLAND,
  countryCodes.FRANCE,
  countryCodes.GERMANY,
  countryCodes.SWITZERLAND,
  countryCodes.AUSTRIA,
  countryCodes.HUNGARY,
  countryCodes.IRELAND,
  countryCodes.ITALY,
  countryCodes.NORWAY,
  countryCodes.POLAND,
  countryCodes.PORTUGAL,
  countryCodes.SPAIN,
  countryCodes.SWEDEN,
  countryCodes.UNITED_KINGDOM,
  countryCodes.ARGENTINA,
  countryCodes.BRAZIL,
  countryCodes.COLOMBIA,
  countryCodes.PERU,
  countryCodes.MEXICO,
  countryCodes.SOUTH_AFRICA,
  countryCodes.AUSTRALIA,
  countryCodes.NEW_ZEALAND,
  countryCodes.CHINA,
  countryCodes.HONG_KONG,
  countryCodes.INDIA,
  countryCodes.INDONESIA,
  countryCodes.MALAYSIA,
  countryCodes.BAHRAIN,
  countryCodes.EGYPT,
  countryCodes.IRAN,
  countryCodes.IRAQ,
  countryCodes.JORDAN,
  countryCodes.KUWAIT,
  countryCodes.LEBANON,
  countryCodes.OMAN,
  countryCodes.QATAR,
  countryCodes.SAUDI_ARABIA,
  countryCodes.SOUTH_SUDAN,
  countryCodes.SUDAN,
  countryCodes.SYRIAN_ARAB_REPUBLIC,
  countryCodes.UNITED_ARAB_EMIRATES,
  countryCodes.YEMEN,
  countryCodes.PHILIPPINES,
  countryCodes.SINGAPORE,
  countryCodes.KOREA,
  countryCodes.TAIWAN,
  countryCodes.THAILAND,
  countryCodes.VIETNAM,
  countryCodes.CHILE,
  countryCodes.URUGUAY,
] as const;

export const MARKETING_OPT_IN_CONFIG: MarketingConsentConfig = {
  emailMarketing: [
    countryCodes.CANADA,
    countryCodes.BELGIUM,
    countryCodes.NETHERLANDS,
    countryCodes.LUXEMBOURG,
    countryCodes.FINLAND,
    countryCodes.FRANCE,
    countryCodes.GERMANY,
    countryCodes.SWITZERLAND,
    countryCodes.AUSTRIA,
    countryCodes.HUNGARY,
    countryCodes.IRELAND,
    countryCodes.ITALY,
    countryCodes.DENMARK,
    countryCodes.NORWAY,
    countryCodes.POLAND,
    countryCodes.PORTUGAL,
    countryCodes.SPAIN,
    countryCodes.SWEDEN,
    countryCodes.UNITED_KINGDOM,
    countryCodes.ARGENTINA,
    countryCodes.BRAZIL,
    countryCodes.COLOMBIA,
    countryCodes.PERU,
    countryCodes.SOUTH_AFRICA,
    countryCodes.AUSTRALIA,
    countryCodes.NEW_ZEALAND,
    countryCodes.CHINA,
    countryCodes.HONG_KONG,
    countryCodes.INDIA,
    countryCodes.INDONESIA,
    countryCodes.MALAYSIA,
    countryCodes.PHILIPPINES,
    countryCodes.SINGAPORE,
    countryCodes.KOREA,
    countryCodes.TAIWAN,
    countryCodes.THAILAND,
    countryCodes.VIETNAM,
    ...MIDDLE_EAST_COUNTRIES,
  ],
  marketingProfiling: [
    countryCodes.BELGIUM,
    countryCodes.NETHERLANDS,
    countryCodes.LUXEMBOURG,
    countryCodes.ITALY,
    countryCodes.DENMARK,
    countryCodes.NORWAY,
    countryCodes.PORTUGAL,
    countryCodes.SPAIN,
    countryCodes.INDIA,
  ],
  advertisingProfiling: [
    countryCodes.BELGIUM,
    countryCodes.NETHERLANDS,
    countryCodes.LUXEMBOURG,
    countryCodes.GERMANY,
    countryCodes.SWITZERLAND,
    countryCodes.AUSTRIA,
    countryCodes.HUNGARY,
    countryCodes.DENMARK,
    countryCodes.NORWAY,
    countryCodes.BRAZIL,
    countryCodes.CHINA,
    countryCodes.INDIA,
    countryCodes.KOREA,
    countryCodes.TAIWAN,
  ],
  analyticsProfiling: [
    countryCodes.NORWAY,
    countryCodes.DENMARK,
    countryCodes.ARGENTINA,
    countryCodes.INDIA,
    countryCodes.INDONESIA,
  ],
  dataSharing: [
    countryCodes.CANADA,
    countryCodes.BELGIUM,
    countryCodes.NETHERLANDS,
    countryCodes.LUXEMBOURG,
    countryCodes.FRANCE,
    countryCodes.GERMANY,
    countryCodes.SWITZERLAND,
    countryCodes.AUSTRIA,
    countryCodes.HUNGARY,
    countryCodes.ITALY,
    countryCodes.DENMARK,
    countryCodes.NORWAY,
    countryCodes.POLAND,
    countryCodes.PORTUGAL,
    countryCodes.SPAIN,
    countryCodes.ARGENTINA,
    countryCodes.MEXICO,
    countryCodes.AUSTRALIA,
    countryCodes.NEW_ZEALAND,
    countryCodes.CHINA,
    countryCodes.INDIA,
    countryCodes.PHILIPPINES,
    countryCodes.TAIWAN,
  ],
};

export const REQUIRED_MARKETING_OPT_IN_CONFIG = {
  emailMarketing: [countryCodes.SPAIN, countryCodes.PORTUGAL],
};

export const MARKETING_OPT_OUT_CONFIG: Partial<MarketingConsentConfig> = {
  emailMarketing: [countryCodes.MEXICO],
};

export const NO_IP_LOCATION_BASED_MARKETING: MarketingConsentCountry[] = [
  countryCodes.HONG_KONG,
  countryCodes.INDIA,
  countryCodes.MALAYSIA,
  countryCodes.SINGAPORE,
];

export const MARKETING_DOUBLE_OPT_IN_CONFIG: Partial<MarketingConsentConfig> = {
  emailMarketing: [
    countryCodes.LUXEMBOURG,
    countryCodes.GERMANY,
    countryCodes.SWITZERLAND,
    countryCodes.AUSTRIA,
    countryCodes.ITALY,
    countryCodes.SWEDEN,
    countryCodes.COLOMBIA,
    countryCodes.PERU,
  ],
};

// diff between full list of marketing consent countries and countries in configs
// to identify countries that does not require to consent to anything
export const MARKETING_CONSENT_COUNTRIES_WITHOUT_OPT_IN: MarketingConsentCountry[] =
  (() => {
    // Unique countryCodes from configs
    const countriesInConfigs = new Set([
      ...Object.values(MARKETING_OPT_IN_CONFIG).flatMap((array) => array),
      ...Object.values(MARKETING_OPT_OUT_CONFIG).flatMap((array) => array),
    ]);

    return MARKETING_CONSENT_COUNTIES.filter(
      (countryCode) => !countriesInConfigs.has(countryCode)
    );
  })();

export const PRERELEASE_BLOCKED_COUNTRIES = [
  // Asia
  countryCodes.CHINA,
  countryCodes.INDIA,

  // Benelux
  countryCodes.BELGIUM,
  countryCodes.NETHERLANDS,
  countryCodes.LUXEMBOURG,

  countryCodes.DENMARK,

  countryCodes.HUNGARY,

  countryCodes.NORWAY,
];

export const PAGES_BLOCKED_COUNTRIES = [
  // Asia
  countryCodes.CHINA,
  countryCodes.INDIA,
  countryCodes.AFGHANISTAN,
  countryCodes.MYANMAR,
  countryCodes.KOREA_DEMOCRATIC_PEOPLES_REPUBLIC, // North Korea

  // Middle East
  countryCodes.IRAQ,
  countryCodes.IRAN,
  countryCodes.LEBANON,
  countryCodes.SUDAN,
  countryCodes.SOUTH_SUDAN,
  countryCodes.SYRIAN_ARAB_REPUBLIC,
  countryCodes.YEMEN,

  // Africa
  countryCodes.CENTRAL_AFRICAN_REPUBLIC,
  countryCodes.CONGO_DEMOCRATIC_REPUBLIC,
  countryCodes.ETHIOPIA,
  countryCodes.LIBYAN_ARAB_JAMAHIRIYA, // Libya
  countryCodes.MALI,
  countryCodes.SOMALIA,
  countryCodes.COTE_D_IVOIRE, // Ivory Coast
  countryCodes.ZIMBABWE,

  // Eastern Europe & Post-Soviet States
  countryCodes.BELARUS,
  countryCodes.RUSSIAN_FEDERATION,
  countryCodes.UKRAINE,

  // The Balkans
  countryCodes.ALBANIA,
  countryCodes.BOSNIA_AND_HERZEGOVINA,
  countryCodes.BULGARIA,
  countryCodes.CROATIA,
  countryCodes.MONTENEGRO,
  countryCodes.MACEDONIA,
  countryCodes.ROMANIA,
  countryCodes.SERBIA,
  countryCodes.SLOVENIA,

  // Latin America
  countryCodes.CUBA,
  countryCodes.NICARAGUA,
  countryCodes.VENEZUELA,
];

export const MUST_ACCEPT_PRIVACY_POLICY_COUNTRIES: string[] = [
  ...MIDDLE_EAST_COUNTRIES,
];

const MIDDLE_EAST_PP_TEXT = {
  en: `
This Policy is effective from 31 July 2024. 
This privacy (“Privacy Policy”) explains the practices of Sony Music Entertainment Middle East FZ LLC (“SME”) in relation to the collection, use and disclosure of personal data on our websites, online and offline services, landing pages, apps, online stores, podcasts, email correspondence, and any other site dedicated to the release of Sony Music Entertainment products or services (“Sites”), together with any entity of Sony Music Entertainment using your data in accordance with this Privacy Policy (collectively, “Sony Music,” “we,” “us,” “our”). Sony Music ME is a company registered in United Arab Emirates whose registered address is P. O. Box 502812, Office 210-212, Building 6, Dubai Media City, Dubai, United Arab Emirates. Please read the text carefully to understand our views and practices regarding your information. By accessing this system you have consented for us processing the personal data in the manner as detailed herein. 

1. APPLICATION OF THIS PRIVACY POLICY 1.1 This Privacy Policy (“Policy”) forms a part of the Terms and Conditions (“Terms”) relating to the use of the Sony Music Sites and the Cookies Policy found here. 1.2 Sony Music Entertainment Middle East FZ-LLC (“SME”, “we”, “us” or “our”) is committed to safeguarding the privacy of the personal information that is provided to us or collected by us during the course of our business as well as the personal information we receive from you (“User”, “you” or “your”) to the Sites. This Policy describes how and why we collect, store and use personal information, and provides information about your rights. Please read the following information carefully to understand our views and practices regarding how we handle personal information. 1.3 For the purposes of applicable data protection law, SME is the “data controller” of your personal information and data. 1.4 For purposes hereof, “services” means and includes access to and use of the Sites. 1.5 Please read the text carefully to understand our views and practices regarding your personal data and how we will treat it.

2. COLLECTION OF PERSONAL INFORMATION 2.1 By using the Sites, you consent to our collection, use and disclosure of your personal information in accordance with the terms of this Policy. 2.2 Please refer to section 8, below, regarding how we use your data and the purposes for which we process your data. 2.3 You may withdraw such consent at any time, however this will not affect the lawfulness of the processing based on your consent prior to the withdrawal, or where we have other legitimate interest and reasons for processing your personal data. 

3. MINORS A SPECIAL NOTE TO CHILDREN UNDER 18 YEARS OF AGE YOU MUST BE 21 YEARS OF AGE OR OLDER TO REGISTER WITH OUR SITE. PLEASE DO NOT REGISTER IF YOU ARE UNDER 18 YEARS OF AGE. We are committed to complying with Federal Law No.3 of 2016 on child rights (Children’s Law). Our site and services are not directed to children under the age of 18. We do not knowingly collect personal information from children under the age of 18. If a user identifies him or herself as a child under the age of 18, we will not collect, store or use any personal information. If we receive personal information that we discover was provided by a child under the age of 18, we will promptly destroy such information. A SPECIAL NOTE TO PARENTS AND SCHOOLS While we prohibit registration by children under the age of 18, parents and schools should supervise their children’s online activities and consider using parental and school control tools available from online services and software manufacturers that help provide a child-friendly online environment. These tools can also prevent children from disclosing online their name, address, and other personal information without parental and school permission. The term “parents” also includes legal guardians. 

4. HOW WE COLLECT YOUR DATA We may collect and process the following personal information: 4.1 Information you give us We may collect and process personal data relating to you by you providing such personal data by filling in forms on our site or by you contacting us or by corresponding with us by phone, e-mail or other electronic means (including accepting online “remember me” functions). This includes, but is not limited to, information provided at the time of registering to use (or become a member of) our Sites, subscribing to any services (e.g. newsletters, podcasts or voice activated content, alerts, special invitations), promotions, purchasing downloads or other products, posting material, participating in contest or opportunity or requesting further services. When you participate in a promotional offer, then we may request and process with your consent for your personal information including your name, home or work address, telephone number and e-mail address, bank details/financial information or identification details so we can administer the promotion. We may share this information with business partners of ours participating in or supporting the promotion.
`,
  local: `
سياسة الخصوصية

تسري هذه السياسة اعتبارًا من 31 يوليو 2024

 توضح هذه السياسة كيفية تعامل شركة سوني للترفيه الموسيقي الشرق الأوسط المحدودة ذ.م.م (“SME”) مع جمع واستخدام ومشاركة البيانات الشخصية عبر مواقعنا وخدماتنا المختلفة، سواء كانت عبر الإنترنت أو خارجها. تشمل هذه السياسة جميع الكيانات التابعة لشركة سوني التي تستخدم بياناتك وفقًا لهذه الشروط. شركة سوني مسجلة في الإمارات العربية المتحدة، وعنوانها المسجل هو صندوق بريد 502812، مكتب 211-212، مبنى 6، مدينة دبي للإعلام، دبي، الإمارات العربية المتحدة. يرجى قراءة النص بعناية لفهم آرائنا وممارساتنا فيما يتعلق ببياناتك. بدخولك إلى هذا النظام، فإنك توافق على قيامنا بمعالجة البيانات الشخصية بالطريقة المفصلة هنا.

1. تطبيق سياسة الخصوصية 1.1 تشكل سياسة الخصوصية هذه (“السياسة”) جزءًا من الشروط والأحكام (“الشروط”) المتعلقة باستخدام مواقع سوني وسياسة ملفات تعريف الارتباط المتوفرة هنا. 1.2 تلتزم شركة سوني للترفيه الموسيقي الشرق الأوسط المحدودة ذ.م.م (“نحن”) بحماية خصوصية معلوماتك الشخصية التي تقدمها لنا أو التي نجمعها أثناء أعمالنا، بالإضافة إلى المعلومات التي نتلقاها منك (“أنت”) عبر مواقعنا. توضح هذه السياسة كيفية وسبب جمعنا واستخدامنا وتخزيننا لهذه المعلومات، وتوضح حقوقك المتعلقة بها. يرجى قراءة ما يلي لفهم كيفية تعاملنا مع معلوماتك الشخصية. 1.3 وفقًا لقوانين حماية البيانات المعمول بها، تعتبر سوني “المتحكم في البيانات” لمعلوماتك الشخصية وبياناتك. 1.4 لأغراض هذه السياسة، تعني “الخدمات” الوصول إلى واستخدام المواقع. 1.5 يرجى قراءة النص بعناية لفهم آرائنا وممارساتنا بشأن بياناتك الشخصية وكيفية تعاملنا معها.

2. جمع المعلومات الشخصية 2.1 باستخدامك للمواقع، فإنك توافق على جمعنا واستخدامنا وكشفنا عن معلوماتك الشخصية وفقًا لشروط هذه السياسة. 2.2 يرجى الرجوع إلى القسم 8 أدناه لمعرفة كيفية استخدامنا لبياناتك والأغراض التي نقوم بمعالجتها من أجلها. 2.3 يمكنك سحب موافقتك في أي وقت، لكن ذلك لن يؤثر على قانونية المعالجة التي تمت قبل سحب الموافقة، أو في حال كان لدينا مصلحة مشروعة أخرى لمعالجة بياناتك.

3. القاصرون ملاحظة خاصة للأطفال تحت سن 18 عامًا يجب أن يكون عمرك 21 عامًا أو أكثر للتسجيل في موقعنا. يُرجى عدم التسجيل إذا كنت أقل من 18 عامًا. نحن نلتزم بالقانون الاتحادي رقم 3 لعام 2016 بشأن حقوق الطفل. موقعنا وخدماتنا غير موجهة للأطفال تحت سن 18 عامًا، ولا نقوم بجمع معلومات شخصية من هؤلاء الأطفال. إذا تبين لنا أن المستخدم تحت سن 18، لن نقوم بجمع أو تخزين أو استخدام بياناته. وإذا تلقينا بيانات من طفل دون سن 18، سنقوم بتدميرها فورًا. ملاحظة خاصة للآباء والمدارس بينما نحظر تسجيل الأطفال دون سن 18، يجب على الآباء والمدارس متابعة الأنشطة الإلكترونية للأطفال واستخدام أدوات الرقابة المتاحة لضمان بيئة آمنة على الإنترنت. تساعد هذه الأدوات في منع الأطفال من مشاركة معلومات شخصية مثل الاسم والعنوان دون إذن. يشمل مصطلح “الآباء” أيضًا الأوصياء القانونيين.

4. كيفية جمع بياناتك قد نقوم بجمع ومعالجة المعلومات الشخصية التالية: 4.1 المعلومات التي تقدمها لنا قد نقوم بجمع بياناتك الشخصية التي تقدمها عند ملء النماذج على موقعنا، أو عند التواصل معنا عبر الهاتف، البريد الإلكتروني، أو وسائل الاتصال الإلكترونية الأخرى (مثل قبول وظائف “تذكرني”). يشمل ذلك المعلومات المقدمة عند التسجيل، الاشتراك في خدمات مثل النشرات الإخبارية والبودكاست، شراء منتجات، نشر مواد، أو المشاركة في المسابقات. عندما تشارك في عرض ترويجي، قد نطلب ونعالج معلوماتك الشخصية مثل الاسم، العنوان، رقم الهاتف، والبريد الإلكتروني، بالإضافة إلى تفاصيل مصرفية أو معلومات مالية أو تعريفية، وذلك بإذن منك لإدارة العرض. قد نشارك هذه المعلومات مع شركائنا التجاريين المشاركين في العرض أو الداعمين له. إذا تواصلت معنا عبر البريد الإلكتروني، ستشاركنا عنوان بريدك الإلكتروني واسمك وأي معلومات شخصية أخرى قد تتضمنها رسالتك. إذا شاركت في مسابقة لسوني على وسائل التواصل الاجتماعي بالتعليق أو باستخدام وسم المسابقة، سنجمع اسم المستخدم الخاص بك وأحيانًا اسمك لتحديد الفائزين والتواصل معك عبر رسالة مباشرة إذا فزت
`,
};

export const REQUIRED_PRIVACY_POLICY_TEXTS: Record<
  string,
  { en: string; local: string }
> = {
  [countryCodes.UNITED_ARAB_EMIRATES]: MIDDLE_EAST_PP_TEXT,
  [countryCodes.BAHRAIN]: MIDDLE_EAST_PP_TEXT,
  [countryCodes.EGYPT]: MIDDLE_EAST_PP_TEXT,
  [countryCodes.IRAQ]: MIDDLE_EAST_PP_TEXT,
  [countryCodes.IRAN]: MIDDLE_EAST_PP_TEXT,
  [countryCodes.JORDAN]: MIDDLE_EAST_PP_TEXT,
  [countryCodes.KUWAIT]: MIDDLE_EAST_PP_TEXT,
  [countryCodes.LEBANON]: MIDDLE_EAST_PP_TEXT,
  [countryCodes.OMAN]: MIDDLE_EAST_PP_TEXT,
  [countryCodes.QATAR]: MIDDLE_EAST_PP_TEXT,
  [countryCodes.SAUDI_ARABIA]: MIDDLE_EAST_PP_TEXT,
  [countryCodes.SUDAN]: MIDDLE_EAST_PP_TEXT,
  [countryCodes.SOUTH_SUDAN]: MIDDLE_EAST_PP_TEXT,
  [countryCodes.SYRIAN_ARAB_REPUBLIC]: MIDDLE_EAST_PP_TEXT,
  [countryCodes.YEMEN]: MIDDLE_EAST_PP_TEXT,
};
